import appCssUrl from '../styles/app.css?url';
import prismCssUrl from '../styles/prism.css?url';
import legalBoilerplateCssUrl from '../styles/legal-boilerplate.css?url';

export type StylesType = { rel: string; href: string };

export const PrismCssUrl = prismCssUrl;

export const Styles: { [key: string]: StylesType[] } = {
  app: [{ rel: 'stylesheet', href: appCssUrl }],
  legalBoilerplate: [
    { rel: 'stylesheet', href: appCssUrl },
    { rel: 'stylesheet', href: legalBoilerplateCssUrl },
  ],
  prism: [{ rel: 'stylesheet', href: prismCssUrl }],
};
